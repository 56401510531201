import React from 'react';
import logo from './logo.png';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import {QRScanDialog} from './QRScanDialog';
import {
  useSearchParams, 
  BrowserRouter, 
  Routes, 
  Route
} from "react-router-dom";
import {
  Button,
  Paper,
  Grid,
  IconButton,
  Divider,
  Typography
} from '@material-ui/core';
import { 
  ThemeProvider, 
  createTheme,
  makeStyles 
} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RefreshIcon from '@material-ui/icons/Refresh';
import DownloadIcon from '@material-ui/icons/GetApp';
import { green, grey, red } from '@material-ui/core/colors';

const C_PARAM = 'c';
const S_PARAM = 's';
const G_PARAM = 'a';

const errors = {
  code: 'Неверный код!',
  device: 'Неверный код!',
  lic: 'Ошибка получения лицензии!',
  coupon_not_found: 'Купон не найден в ЛК!',
  coupon_not_valid: 'Купон невалиден!',
  coupon_activated: 'Купон был ранее активирован!',
  internal_error: 'Внутрення ошибка, попробуйте позже!',
  overlimit: 'Превышен лимит лицензий!',
  coupn_not_saved: 'Купон не сохранен!'
};

function App() {
  const darkTheme = createTheme({
    palette: {
      type: 'dark',
      text: {
        primary: '#fff',
        secondary: '#fff',
        disabled: '#fff'
      },
      background: {
        paper: '#fff'
      }
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}/>
          </Routes>
        </BrowserRouter>
    </ThemeProvider>
  )
}

function Home() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [generating, setGenerating] = React.useState(false);
  const [openCDialog, setOpenCDialog] = React.useState(false);
  const [openGDialog, setOpenGDialog] = React.useState(false);
  const [cVal, setCVal] = React.useState(null);
  const [sVal, setSVal] = React.useState(null);
  const [pVal, setPVal] = React.useState(null);
  const [gVal, setGVal] = React.useState(null);
  const [lVal, setLVal] = React.useState(null);
  const [fVal, setFVal] = React.useState(null);
  const [rVal, setRVal] = React.useState(null);
  const classes = useStyles();

  React.useEffect(() => {
    let valC = searchParams.get(C_PARAM);
    let valS = searchParams.get(S_PARAM);
    if (valC != null && valS != null) {
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({c: valC, s: valS})
      };
      fetch('/checkc', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.check) {
            valC = getCodeError(data.e);
            valS = getCodeError(data.e);
          }
          localStorage.setItem('cVal', valC);
          searchParams.delete(C_PARAM);
          setSearchParams(searchParams);
          localStorage.setItem('sVal', valS);
          searchParams.delete(S_PARAM);
          setSearchParams(searchParams);
          localStorage.setItem('pVal', data.p);

          val = localStorage.getItem('cVal');
          setCVal(val);
          val = localStorage.getItem('sVal');
          setSVal(val);
          val = localStorage.getItem('pVal');
          setPVal(val);
        })
        .catch((err) => {
          console.log(err.message);
          localStorage.setItem('cVal', errors.code);
          searchParams.delete(C_PARAM);
          setSearchParams(searchParams);
          localStorage.setItem('sVal', errors.code);
          searchParams.delete(S_PARAM);
          setSearchParams(searchParams);
          localStorage.setItem('pVal', "");

          val = localStorage.getItem('cVal');
          setCVal(val);
          val = localStorage.getItem('sVal');
          setSVal(val);
          val = localStorage.getItem('pVal');
          setPVal(val);
        });
    }
    let valG = searchParams.get(G_PARAM);
    console.log('val0=', valG);
    if (valG != null) {
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({a: valG})
      };
      fetch('/checka', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.check) {
            valG = errors.device;
          }
          console.log('val1=', valG);
          localStorage.setItem('gVal', valG);
          searchParams.delete(G_PARAM);
          setSearchParams(searchParams);

          valG = localStorage.getItem('gVal');
          setGVal(valG);
        })
        .catch((err) => {
          console.log(err.message);
          localStorage.setItem('gVal', errors.device);
          searchParams.delete(G_PARAM);
          setSearchParams(searchParams);

          valG = localStorage.getItem('gVal');
          setGVal(valG);
        });
    }

    let val = localStorage.getItem('cVal');
    setCVal(val);
    val = localStorage.getItem('sVal');
    setSVal(val);
    val = localStorage.getItem('pVal');
    setPVal(val);
    val = localStorage.getItem('gVal');
    setGVal(val);
    val = localStorage.getItem('lVal');
    setLVal(val);
    val = localStorage.getItem('fVal');
    setFVal(val);
    val = localStorage.getItem('rVal');
    setRVal(val === 'true' ? true : false);

  }, [cVal, setCVal, sVal, setSVal, pVal, setPVal,
    gVal, setGVal, lVal, setLVal, fVal, setFVal, rVal, setRVal,
    searchParams, setSearchParams]);

  const isError = (err) => {
    var res = false;
    for (const [key, value] of Object.entries(errors)) {
      if (value === err)
        res = true;
    }
    return res;
  };

  const genLicDisabled = cVal == null || sVal == null || gVal == null || generating
    || isError(cVal) === true || isError(sVal) === true || isError(gVal) === true;

  const onStartOver = (e) => {
    setCVal(null);
    setSVal(null);
    setPVal(null);
    setGVal(null);
    setLVal(null);
    setFVal(null);
    setRVal(null);
    localStorage.removeItem('cVal');
    localStorage.removeItem('sVal');
    localStorage.removeItem('pVal');
    localStorage.removeItem('gVal');
    localStorage.removeItem('lVal');
    localStorage.removeItem('fVal');
    localStorage.removeItem('rVal');
  };

  const getCodeError = (err) => {
    if (err === 'GENLIC_COUPONNOTFOUND')
      return errors.coupon_not_found;
    return errors.code;
  };

  const getLicError = (err) => {
    switch (err) {
      case 'GENLIC_COUPONNOTFOUND':
        return errors.coupon_not_found;
      case 'GENLIC_COUPONNOTVALID':
        return errors.coupon_not_valid;
      case 'GENLIC_INTERNALERROR':
        return errors.internal_error;
      case 'GENLIC_COUPONACTIVATED':
        return errors.coupon_activated;
      case 'GENLIC_ERROR':
        return errors.lic;
      case 'GENLIC_OVERLIMIT':
        return errors.overlimit;
      case 'GENLIC_COUPONNOTSAVED':
        return errors.coupn_not_saved;
      default:
        return errors.lic;
    }
  };

  const onGenLic = (e) => {
    setGenerating(true);
    console.log('GenLic ' + cVal + ' ' + pVal + ' ' + gVal);
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({c: cVal, p: pVal, a: gVal})
    };
    fetch('/genlic', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        var lstr = null;
        var fstr = null;
        var r = null;
        if (data.l.length > 0 && data.e.length == 0) {
          lstr = data.l;
        } else if (data.f.length > 0 && data.e.length == 0) {
          fstr = data.f;
        } else {
          lstr = getLicError(data.e);
        }
        r = data.r;

        localStorage.setItem('lVal', lstr);
        localStorage.setItem('fVal', fstr);
        localStorage.setItem('rVal', r === true ? 'true' : 'false');
        setLVal(lstr);
        setFVal(fstr);
        setRVal(r);

        setGenerating(false);
      })
      .catch((err) => {
        console.log(err.message);
        localStorage.setItem('lVal', errors.lic);
        localStorage.setItem('fVal', errors.lic);
        localStorage.setItem('rVal', 'false');
        setLVal(errors.lic);
        setFVal(errors.lic);
        setRVal(false);
        setGenerating(false);
      });
  };

  const handleCDialogOpen = () => {
    setOpenCDialog(true);
  };

  const handleCDialogClose = (value, isValid) => {
    setOpenCDialog(false);
    if (value == null && !isValid)
      return;

    let ok = false;
    try {
      const url = new URL(value);
      const valC = url.searchParams.get(C_PARAM);
      const valS = url.searchParams.get(S_PARAM);
      if (valC != null && valS != null) {
        localStorage.setItem('cVal', valC);
        localStorage.setItem('sVal', valS);
        setCVal(valC);
        setSVal(valS);
        ok = true;
      }
    } catch (e) {}
    if (!ok) {
      localStorage.setItem('cVal', errors.code);
      localStorage.setItem('sVal', errors.code);
      setCVal(errors.code);
      setSVal(errors.code);
    }
  };

  const handleGDialogOpen = () => {
    setOpenGDialog(true);
  };

  const handleGDialogClose = (value, isValid) => {
    setOpenGDialog(false);
    if (value == null && !isValid)
      return;

    let ok = false;
    try {
      const url = new URL(value);
      const val = url.searchParams.get(G_PARAM);
      if (val != null) {
        localStorage.setItem('gVal', val);
        setGVal(val);
        ok = true;
      }
    } catch (e) {}
    if (!ok) {
      localStorage.setItem('gVal', errors.device);
      setGVal(errors.device);
    }
  };

  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  };

  const saveBlob = (fileName, blob) => {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  console.log('rVal', rVal);
  return (
    <div className="App">
      <header className="App-header">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img src={logo} className="App-logo" alt="logo" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>Код активации</Typography>
            <Paper className={classes.root}>
              {isError(cVal) === true || isError(sVal) === true ?
                <CancelIcon 
                  className={classes.iconError}/>
                :
                <CheckCircleIcon 
                  className={cVal != null && sVal != null ? classes.iconOk : classes.iconNotOk}/>
              }
              {cVal != null && sVal != null ? 
                <Typography variant='h6' className={classes.text}>
                  {isError(cVal) === true ? cVal : 'Код успешно введен'}
                </Typography>
                :
                <Typography variant='subtitle1' className={classes.noText}>
                  {'Отсканируйте QR код на Карте активации'}
                </Typography>
              }
              <Divider 
                className={classes.divider} 
                orientation="vertical"/>
              <IconButton 
                className={classes.iconButton} 
                onClick={handleCDialogOpen}>
                <PhotoCameraIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>Код ШГУ</Typography>
            <Paper className={classes.root}>
              {isError(gVal) === true ?
                <CancelIcon 
                  className={classes.iconError}/>
                :
                <CheckCircleIcon 
                  className={gVal != null ? classes.iconOk : classes.iconNotOk}/>
              }
              {gVal != null ? 
                <Typography variant='h6' className={classes.text}>
                  {isError(gVal) === true ? gVal : 'Код успешно введен'}
                </Typography>
                :
                <Typography variant='subtitle1' className={classes.noText}>
                  {'Отсканируйте QR код на экране ШГУ'}
                </Typography>
              }
              <Divider 
                className={classes.divider} 
                orientation="vertical"/>
              <IconButton 
                className={classes.iconButton} 
                onClick={handleGDialogOpen}>
                <PhotoCameraIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={7}>
            <Button id="getLicButton"
              startIcon={<LockIcon />}
              disabled={genLicDisabled}
              variant="contained" 
              color='primary'
              onClick={onGenLic}>
                Получить лицензию
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button id="getLicButton"
              startIcon={<RefreshIcon />}
              variant="contained" 
              color='default'
              onClick={onStartOver}>
                Начать заново
            </Button>
          </Grid>
          <Grid item xs={12}>
            {(lVal != null && lVal !== 'null') &&
              <div>
                <Typography variant='h5' margin={1}>Код лицензии</Typography>
                <Paper 
                  className={classes.root}>
                  {isError(lVal) === true ?
                    <CancelIcon 
                      className={classes.iconError}/>
                    :
                    <CheckCircleIcon 
                      className={classes.iconOk}/>
                  }
                  <Typography variant='h6' className={classes.text}>
                    {`${isError(lVal) === false && rVal === true ? 'Лицензия восстановлена, купон не активирован!' : ''}`}
                    {isError(lVal) === false && rVal === true && <br/>}
                    {lVal}
                  </Typography>
                </Paper>
              </div>
            }
            {(fVal != null && fVal !== 'null') &&
                <div>
                  <Typography variant='h5' margin={1}>Файл лицензии</Typography>
                    <Paper className={classes.root}>
                      <CheckCircleIcon
                          className={classes.iconOk}/>
                      <Typography variant='h6' className={classes.text}>
                        {`${rVal === true ? 'Лицензия восстановлена, купон не активирован!' : ''}`}
                        {rVal === true && <br/>}
                        <Button
                            id="downloadLicButton"
                            startIcon={<DownloadIcon />}
                            variant="contained"
                            style={{margin: '16px'}}
                            color='primary'
                            onClick={(e) => {
                              const blob = b64toBlob(fVal, 'application/octet-stream');
                              saveBlob('device-license.bin', blob);
                            }}>
                          Скачать
                        </Button>
                      </Typography>
                    </Paper>
                </div>
            }
          </Grid>
        </Grid>
        <QRScanDialog
          open={openCDialog}
          onClose={handleCDialogClose}
        />
        <QRScanDialog
          open={openGDialog}
          onClose={handleGDialogClose}
        />
      </header>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width:'100%', 
    padding:'10px', 
    display: 'flex', 
    alignItems: 'center'
  },
  iconOk: {
    color: green[500],
  },
  iconNotOk: {
    color: grey[500],
  },
  iconError: {
    color: red[500],
  },
  noText: {
    color: grey[500],
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: 1,
  },
  text: {
    color: 'black',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    color: grey[900],
    padding: 0,
  },
  divider: {
    background: grey[500],
    height: '3vh',
    marginRight: 8,
  },
}));

export default App;
