import React from 'react';
import PropTypes from 'prop-types';
import QrReader from "react-qr-reader";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

export function QRScanDialog({ onClose, open }) {

  const handleClose = () => {
    onClose(null, false);
  };

  const handleScan = data => {
    if (data != null)
      onClose(data, true);
  };

  const handleError = err => {
    console.error(err);
    onClose('error', false);
  };

  const constraints = {
    facingMode: { exact: "environment" },
  };

  return (
    <Dialog 
      onClose={handleClose} 
      open={open}
      PaperProps={{ style: { width: "80%" } }}>
      <QrReader
        delay={300}
        constraints={constraints}
        onScan={handleScan}
        onError={handleError}
        style={{ width: "100%" }}
      />
      <Button 
        variant="contained" 
        onClick={handleClose}>
        Отмена
      </Button>
    </Dialog>
  );
}

QRScanDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
